$(document).ready(function() {
	$('input[name="moneyback"]').click(function(){
		var $bankInfo = $('.moneyback').find('.right-column');
		if($(this).val() == 'bani_inapoi') {
			$bankInfo.show();
		} else {
			$bankInfo.hide();
		}
	});
});

var rd = {

	init: function() {

		// activate tooltip for options
		$('[data-toggle="tooltip"]').tooltip();

		// disable parent[0] menu click
		this.disableMenuClick();

		// live udpate the option prices
		if($('#product').length > 0) {
			this.instantPrice();
		}
	},

	disableMenuClick: function() {
		$("#primary-menu").find('.parent.has-submenu > a').click(function(e){
			e.preventDefault();
		});
	},

	instantPrice : function() {

		// marked first radio btn aschecked
		$('.radio').parents('.form-group').find('.radio:eq(0) label').click();

		$('.radio label span').click(function(e){


			var currency           = $(this).attr('data-currency');
			var $wrap_radios       = $(this).parents('.form-group');

			if(!$(this).hasClass('active')) {

				// change the active class
				$wrap_radios.find('span.active').removeClass('active');
				$(this).addClass('active');

				var option_final_price = $(this).closest('[data-start-price]').data('start-price');
				var option_special     = $(this).closest('[data-start-price-special]').data('start-price-special');
				$(this).closest('#product').find('span.active').each(function (index, element) {
					option_final_price += $(element).data('op-price');
					option_special += $(element).data('op-price');
				})

				// update showing price
				$('.top-price li:last-child').find('h2').html(option_final_price + ''+currency+'');
				// if we have special with line through we update it as well
				if($('.top-price li').length > 1) {
					$('.top-price li:first-child span').html(option_special + ''+currency+'');
				}

				// update tooltip price for the other radio (in case we have 2 form groups)
				$wrap_radios.siblings('.form-group').each(function(){
					
					// only groups with custom radio
					if($(this).find('.radio').length > 0) {

						// save option values in array
						var arr = new Array();
						$(this).find('.radio').each(function(index, value){ 
							var $info        = $(this).find('span');
							var option_value = parseInt($info.attr('data-op-price'));
							arr[index]       = [option_value, ($info.hasClass('active') ? true : false) ];
						});

						// update each time the option final price
						$(this).find('.radio').each(function(index, value){

								var $info = $(this).find('span');
								// if($info.hasClass('active')) {

								// }

								var x = $wrap_radios.find('span.active').data('op-price');
								var update_price   = $(this).closest('[data-start-price]').data('start-price') + x + arr[index][0];
								// console.log($(this).closest('.form-group').find('span.active').data('op-price'),$(this).closest('[data-start-price]').data('start-price')+x, x, arr[index][0])
								var update_special = $(this).closest('[data-start-price-special]').data('start-price') + x + arr[index][0];

								

								$info.attr({
									'data-special' : update_special, 
									'data-price' : update_price,
									'data-original-title' : update_price+currency
								});
								// console.log($info);

						});
						// console.log(arr);
					}
				});

			} // end !active
		});
	}

};

$(function(){

	// init default functions of this theme
	rd.init();

});